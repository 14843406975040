export function addLabelsToTds($tables) {
    $tables.each(function () {
        let $table = $(this);
        let $heads = $table.find('thead th');
        $table.find('tbody tr').each(function () {
            let $row = $(this);
            $row.find('td').each(function (index) {
                let text = $heads.eq(index).text();
                if(text != '') {
                    $(this).attr('data-label', $heads.eq(index).text());
                }
            });
        });
    });
}