$(document).ready(function () {


    //funnel slider backward
    function funnelSliderBackward(){

        var activeSlide = $('#funnel .slides > li.active');
        var lastSlide = $('#funnel .slides > li:last-of-type');
        var currentSlideNumber = activeSlide.data('slide');
        var prevSlideNumber = currentSlideNumber - 1;
        var prevSlide = $('#funnel .slides > li[data-slide="'+prevSlideNumber+'"]');


        //hide current slide
        activeSlide.removeClass('active').addClass('inactive');

        //porgressbar
        $('.progress-bar span').width(20*prevSlideNumber+'%');

        //back
        if(prevSlideNumber > 1){
            $('.funnel__footer__back').addClass('active');
        }else{
            $('.funnel__footer__back').removeClass('active');
        }

        //button submit
        if(prevSlideNumber == 4){
            $('.funnel__footer--right .button--black').fadeOut(400);
            setTimeout(function(){
                $('.funnel__footer--right .button--ghost').fadeIn(600);
            },500);
        }

        //show next slide
        setTimeout(function(){
            prevSlide.insertBefore(activeSlide);
        }, 600);
        setTimeout(function(){
            prevSlide.removeClass('inactive').addClass('active');
        }, 700);

        //back to top
        $('html, body').animate({
            scrollTop: $("#funnel").offset().top
        }, 800);

    }

    $('.funnel__footer__back').on('click', function(){
        funnelSliderBackward();
    });

    //funnel slider Forward
    function funnelSliderForward(){

        var activeSlide = $('#funnel .slides > li.active');
        var lastSlide = $('#funnel .slides > li:last-of-type');
        var currentSlideNumber = activeSlide.data('slide');
        var nextSlideNumber = currentSlideNumber + 1;
        var nextSlide = $('#funnel .slides > li[data-slide="'+nextSlideNumber+'"]');


        //hide current slide
        activeSlide.removeClass('active').addClass('inactive');

        //porgressbar
        $('.progress-bar span').width(25*nextSlideNumber+'%');

        //back
        if(nextSlideNumber > 1){
            $('.funnel__footer__back').addClass('active');
        }else{
            $('.funnel__footer__back').removeClass('active');
        }

        //button submit
        if(nextSlideNumber == 4){
            $('.funnel__footer--right .button--ghost').fadeOut(400);
            setTimeout(function(){
                $('.funnel__footer--right .button--black').fadeIn(600);
            },500);
        }

        //show next slide
        setTimeout(function(){
            activeSlide.insertAfter(lastSlide);
            nextSlide.removeClass('inactive').addClass('active');
        }, 600);

        //back to top
        $('html, body').animate({
            scrollTop: $("#funnel").offset().top
        }, 800);

    }

    $('.funnel__footer--right span.button').on('click', function(){
        funnelSliderForward();
    });

    var firstSlideValidated = false;

    //if porduct = door or window
    $(document).on('change','#funnel select',function(){

        var productVal = $("#product").val();
        var materialVal = $("#material").val();

        //show or hide material
        if (productVal == "chassis" || productVal == "portes"){
            $('.funnel-input.funnel-input--material').removeClass('inactive');
            $('.funnel-input.funnel-input--material select').prop("disabled", false);
        }else{
            $('.funnel-input.funnel-input--material').addClass('inactive');
            $('.funnel-input.funnel-input--material select').prop("disabled", true);
        }

        //check validity
        if((productVal !== "chassis" && productVal !== "portes" && productVal !== "") || ((productVal == "chassis" || productVal == "portes") && !materialVal == "")){
            firstSlideValidated = true;
            $(".funnel__footer--right .button--ghost, .block--funnel-map .button--ghost").removeClass('inactive');
        }else{
            firstSlideValidated = false;
            $(".funnel__footer--right .button--ghost, .block--funnel-map .button--ghost").addClass('inactive');
        }

        //change URL button
        if (productVal == "chassis" || productVal == "portes"){
            $('.block.block--funnel-map .button--ghost').attr("href", "/votre-devis-gratuit/?product="+productVal+"&material="+materialVal);
        }else{
            $('.block.block--funnel-map .button--ghost').attr("href", "/votre-devis-gratuit/?product="+productVal);
        }

    });

    function funnelCheckValidity(){

        var productVal = $("#product").val();
        var materialVal = $("#material").val();

        //check validity
        if((productVal !== "chassis" && productVal !== "portes" && productVal !== "") || ((productVal == "chassis" || productVal == "portes") && !materialVal == "")){
            firstSlideValidated = true;
        }else{
            firstSlideValidated = false;
        }

        if (firstSlideValidated && !$("#name").val() == "" && !$("#telephone").val() == "" && !$("#postcode").val() == "" && !$("#email").val() == "") {
            $(".funnel__footer--right .button--black").removeClass('inactive');
        }else{
            $(".funnel__footer--right .button--black").addClass('inactive');
        }
    }

    $(document).on('change','#funnel input',function(){
        funnelCheckValidity();
    });


    //submit
    $('#funnel_form').on('submit', function(e) { //use on if jQuery 1.7+

        e.preventDefault();  //prevent form from submitting
        if ( $('#honey').val().length !== 0 ) { return false; }

        let formData = new FormData($(this)[0]);

        $.ajax({
            method: "POST",
            url: wp_vars.ajax_url,
            async: false,
            cache: false,
            contentType: false,
            enctype: 'multipart/form-data',
            processData: false,
            data: formData,

            // success
            success: function(){

                $('.funnel__footer--right .button--fill ').addClass('loading');
                setTimeout(function() {
                    $('.funnel__footer--right .button--fill ').fadeOut();
                }, 2000);
                setTimeout(function() {
                    $('.funnel__success').fadeIn();
                }, 2400);
                setTimeout(function(){
                    document.location.href="/";
                }, 6000);

            }

        }).fail(function(){

            $('.funnel__footer--right .button--fill ').addClass('loading');
            setTimeout(function() {
                $('.funnel__footer--right .button--fill ').fadeOut();
            }, 2000);
            setTimeout(function() {
                $('.funnel__error').fadeIn();
            }, 2400);
            setTimeout(function(){
                document.location.href="/";
            }, 6000);

        });
        return false;
    });

    //submit contact
    $('#contact').on('submit', function(e) { //use on if jQuery 1.7+

        e.preventDefault();  //prevent form from submitting
        if ( $('#honey').val().length !== 0 ) { return false; }

        let formData = new FormData($(this)[0]);

        $.ajax({
            method: "POST",
            url: wp_vars.ajax_url,
            async: false,
            cache: false,
            contentType: false,
            enctype: 'multipart/form-data',
            processData: false,
            data: formData,

            // success
            success: function(){

                $('#contact button').addClass('loading');
                $('#contact input, #contact textarea').val('');

                setTimeout(function() {
                    $('#contact').addClass('disabled');
                }, 2000);
                setTimeout(function() {
                    $('.contact__success').fadeIn();
                }, 2400);
                setTimeout(function(){
                    document.location.href="/";
                }, 6000);

            }

        }).fail(function(){

            $('#contact button').addClass('loading');
            setTimeout(function() {
                $('#contact').addClass('disabled');
            }, 2000);
            setTimeout(function() {
                $('.contact__error').fadeIn();
            }, 2400);
            setTimeout(function(){
                document.location.href="/";
            }, 6000);

        });
        return false;
    });



    function getUrlVars() {
        var vars = {};
        var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m,key,value) {
            vars[key] = value;
        });
        return vars;
    }

    //if product in url
    if(getUrlVars()["product"]){
        var product = getUrlVars()["product"];
        var material = getUrlVars()["material"];

        //go to slide 2
        if(product){
            funnelSliderForward();
            $(".funnel__footer--right .button--ghost, .block--funnel-map .button--ghost").removeClass('inactive');
            $('#product').val(product);
            if(material){
                $('#material').val(material);
                $('.funnel-input.funnel-input--material').removeClass('inactive');
            }
        }
    }

});