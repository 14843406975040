import './libs/globals'
import 'lazysizes'
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks';
import './libs/pagination'
import './map'
import './funnel'
import {addLabelsToTds} from "./libs/tables";

// Lorsque le document est prêt, mais tout n'est pas encore loadé ou affiché

$(document).ready(function () {

    // LAZY-LOADING
    $('img').addClass('lazyload');
    //add simple support for background images:
    document.addEventListener('lazybeforeunveil', function(e){
        let bg = e.target.getAttribute('data-bg');
        if (bg){
            e.target.style.backgroundImage = 'url(' + bg + ')';
        }
    });

});

// Après que les images soient loadés
$(window).on('load', function () {

    //loading
    setTimeout(function () {
        $("#loading img").fadeOut("fast");
    }, 50);
    setTimeout(function () {
        $("#loading").fadeOut("fast");
    }, 75);


    //show CTA
    $(window).scroll(function() {
        var scrollTop = $(window).scrollTop();
        var windowHeight = $("body").height() - $(window).height();
        //console.log(windowHeight - scrollTop);
        if((scrollTop >= 500) && (windowHeight - scrollTop >= 800)){
            $('.funnel-cta').addClass('active');
        }else{
            $('.funnel-cta').removeClass('active');
        }
        if(windowHeight - scrollTop <= 100){
            $('.scroll-cta').addClass('active');
        }else{
            $('.scroll-cta').removeClass('active');
        }

        if(windowHeight - scrollTop <= 200){
            $('.funnel-cta--footer').addClass('active');
        }else{
            $('.funnel-cta--footer').removeClass('active');
        }
    });

    //owl carrousel
    $(".visual-text__media.visual-text__gallery .owl-carousel").owlCarousel({
        items:1,
        loop:true,
        autoplay:true,
        autoplayTimeout:5000,
        autoplayHoverPause:true,
        animateIn: 'fadeIn',
        animateOut: 'fadeOut'
    });

    //emergence
    emergence.init({
        elemCushion: 0.2,
        reset: false
    });

    //initial check
    emergence.engage();

    //cookies
    if(!Cookies.get('cookies')) {
        $('.cookies-banner').addClass('active');
    }
    $('.cookies-banner__button').on('click', function(){
        if(!Cookies.get('cookies')) {
            Cookies.set('cookies', 'accepted', {expires: 31});
        }
        $('.cookies-banner').fadeOut();
        //location.reload();
    });


    //main carrousel slides navigation
    $('.carrousel_arrow').on('click', function(){

        //stop automatic carrousel
        clearInterval(refreshIntervalId);
        refreshIntervalId = setInterval(sliderAuto, 7000);
        //clearTimeout(refreshIntervalId);
        //clearTimeout(sliderAuto);

        var currentSlide = $('.carrousel_slide.active');
        var currentSlideNbr = currentSlide.data('slide');
        currentSlideNbr = parseInt(currentSlideNbr);


        //left
        if($(this).hasClass('carrousel_arrow--left')){

            var nextSlideNbr = currentSlideNbr - 1;
            if(currentSlide.hasClass('first-item')){
                var nextSlide = $('.carrousel_slide.last-item');
            }else{
                var nextSlide = $('.carrousel_slide[data-slide="'+nextSlideNbr+'"]');
            }

        //right
        }else{

            var nextSlideNbr = currentSlideNbr + 1;
            if(currentSlide.hasClass('last-item')){
                var nextSlide = $('.carrousel_slide.first-item');
            }else{
                var nextSlide = $('.carrousel_slide[data-slide="'+nextSlideNbr+'"]');
            }

        }

        //add and remove class slides
        currentSlide.removeClass('active').addClass('inactive').removeClass('animation').addClass('animated');
        nextSlide.removeClass('inactive').addClass('active');
        setTimeout(function(){
            nextSlide.removeClass('animated').addClass('animation');
        }, 600);

        //add and remove class number
        nextSlideNbr = nextSlide.data('slide');
        $('.carrousel_numbers li.active').removeClass('active');
        $('.carrousel_numbers li[data-number="'+nextSlideNbr+'"]').addClass('active');


    });

    function sliderAuto(){

        var currentSlide = $('.carrousel_slide.active');
        var currentSlideNbr = currentSlide.data('slide');
        currentSlideNbr = parseInt(currentSlideNbr);

        var nextSlideNbr = currentSlideNbr + 1;
        if(currentSlide.hasClass('last-item')){
            var nextSlide = $('.carrousel_slide.first-item');
        }else{
            var nextSlide = $('.carrousel_slide[data-slide="'+nextSlideNbr+'"]');
        }

        //add and remove class slides
        currentSlide.removeClass('active').addClass('inactive').removeClass('animation').addClass('animated');
        nextSlide.removeClass('inactive').addClass('active');
        setTimeout(function(){
            nextSlide.removeClass('animated').addClass('animation');
        }, 600);

        //add and remove class number
        nextSlideNbr = nextSlide.data('slide');
        $('.carrousel_numbers li.active').removeClass('active');
        $('.carrousel_numbers li[data-number="'+nextSlideNbr+'"]').addClass('active');

    }
    //window.setInterval(function(){
    //    sliderAuto();
    //}, 7000);

    var refreshIntervalId = setInterval(sliderAuto, 7000);

    //main carrousel number navigation
    $('.carrousel_numbers li').on('click', function(){

        if($(this).hasClass("inactive")){
            $('.carrousel_numbers li').removeClass('active').addClass('inactive');
            $(this).addClass('active').removeClass('inactive');

            var slideNbr = $(this).data("number");

            $('.carrousel_slide').removeClass('active').addClass('inactive').removeClass('animation').addClass('animated');

            $('.carrousel_slide[data-slide="'+slideNbr+'"]').removeClass('inactive').addClass('active');
            setTimeout(function(){
                $('.carrousel_slide[data-slide="'+slideNbr+'"]').removeClass('animated').addClass('animation');
            }, 600);
        }

    });


    //slides height
    function slidesHeight(){
        var highestBox = 0;

        $('.carrousel_slide').each(function(){

            if($(this).height() > highestBox) {
                highestBox = $(this).height();
            }

        });

        if($(window).width() >= 700) {
            $('.carrousel_slide').height(highestBox);
        }else{
            $('.carrousel_slide').css('height', 'auto');
        }
    }


    slidesHeight();
    $(window).on('resize', function () {
        slidesHeight();
    });



    //back up
    function scrollTop(){
        $("html, body").animate({scrollTop: 0}, 1000);
    }
    $(".scroll-cta p").click(function () {
        scrollTop();
    });

    //button scroll down
    $(".intro__content .button").click(function (e) {
        e.preventDefault();
        //var vheight = $(window).height();
        var vheight = $(".intro__content").height();
        vheight = vheight + $("header").height();
        $('html, body').animate({
            scrollTop: (Math.floor($(window).scrollTop() / vheight)+1) * vheight
        }, 800);
    });



    //testimonials
    function testimonialSlider(){

        var activeSlide = $('.testimonials__list > li.active');
        var lastSlide = $('.testimonials__list > li:last-of-type');

        activeSlide.removeClass('active').addClass('inactive');

        setTimeout(function(){
            activeSlide.insertAfter(lastSlide);
            $('.testimonials__list > li:first-of-type').removeClass('inactive').addClass('active');
        }, 600);

    }

    setInterval(function () {
        testimonialSlider();
    }, 6000);


    //burger
    $('.burger').on('click', function(){
        $(this).toggleClass('active');
        $('.main-menu--mobile').toggleClass('active');
        $('.header__right').toggleClass('active');
        $('.header__logo').toggleClass('active');
        $('html').toggleClass('overflow-hidden');
    });

    //sub-menu
    $('.menu-item .chevron').on('click', function(){
        $(this).toggleClass('active');
        $(this).closest('.menu-item').find('.sub-menu').slideToggle();
    });

    //sticky header
    //$(window).scroll(function() {

        //var headerHeight = $("header").height();
        //var bannerHeight = $(".message-banner").height();
        //var totalHeight = headerHeight - bannerHeight;
        //var distance = $('header').offset().top;

        //var distance = 300;
        //if ( $(window).scrollTop() >= totalHeight ) {
            //if(!$('.header__placeholder').length) {
            //    $('<div class="header__placeholder" style="height: '+ headerHeight +'px;"></div>').insertBefore('header');
            //}
            //$('header').addClass('fixed');
        //}else{
            //$('.header__placeholder').remove();
            //$('header').removeClass('fixed');
        //}

    //});

    var headerHeight = $("header").height();
    $('.header-placeholder').height(headerHeight);

    //pagination
    $('#pagination').pajinate({
        items_per_page : 5,
        nav_label_first : '',
        nav_label_last : '',
        nav_label_prev : '',
        nav_label_next : ''
    });
    $('.page_link').on('click', function(){
       scrollTop();
    });

    //$("html, body").animate({scrollTop: 0}, 1000);

    //tables
    addLabelsToTds($('.wp-block-table table'));

    //popup
    $('.search__button').on('click', function(){
        $('.popup-container.popup-container--search').fadeIn();
    });
    $('.popup-container--search .popup__quit').on('click', function(){
        $('.popup-container.popup-container--search').fadeOut();
    });

    //video carrousel
    $('.carrousel_slide__play').on('click', function(){
        var iFrame = $('.video-iframe').html();
        iFrame = "<video controls autoplay=''><source src='"+iFrame+"' type='video/mp4'>Your browser does not support the video tag.</video>";
        //console.log(iframe);
        if(!$('.popup-container--video video').length && !$('.popup-container--video iframe').length){
            //$(iFrame).insertBefore('.popup-container--video .popup__quit');
            $('.video-responsive').html(iFrame);
            //$('video').play()
        }
        $('.popup-container.popup-container--video').fadeIn();
    });

    //video visual & text
    $('.visual-text__video').on('click', function(){
        var videoID = $(this).attr('data-video');
        videoID = videoID.split("/"),
        videoID = videoID[videoID.length-1];
        var videoYoutube = '<iframe src="https://www.youtube.com/embed/'+videoID+'?autoplay=1" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'

        if(!$('.popup-container--video video').length && !$('.popup-container--video iframe').length){
            $('.video-responsive').html(videoYoutube);
        }

        $('.popup-container.popup-container--video').fadeIn();
    });


    $('.popup-container--video .popup__quit').on('click', function(){
        $('.video-responsive').empty();
        $('.popup-container.popup-container--video').fadeOut();
    });


    //opening times
    var data = {
        monday: [[$('.monday__morning--start').html(), $('.monday__morning--end').html()], [$('.monday__afternoon--start').html(), $('.monday__afternoon--end').html()]], // (beware! 8.30 means 08:30, using decimal part than is bigger than 59 will generate warning!
        tuesday: [[$('.tuesday__morning--start').html(), $('.tuesday__morning--end').html()], [$('.tuesday__afternoon--start').html(), $('.tuesday__afternoon--end').html()]],
        wednesday: [[$('.wednesday__morning--start').html(), $('.wednesday__morning--end').html()], [$('.wednesday__afternoon--start').html(), $('.wednesday__afternoon--end').html()]],
        thursday: [[$('.thursday__morning--start').html(), $('.thursday__morning--end').html()], [$('.thursday__afternoon--start').html(), $('.thursday__afternoon--end').html()]],
        friday: [[$('.friday__morning--start').html(), $('.friday__morning--end').html()], [$('.friday__afternoon--start').html(), $('.friday__afternoon--end').html()]],
        saturday: [[$('.saturday__morning--start').html(), $('.saturday__morning--end').html()], [$('.saturday__afternoon--start').html(), $('.saturday__afternoon--end').html()]],
        sunday: [] //closed (optional, if entry for a day is empty, it's assumed that it is closed)
    };

    //console.log(data);

    $('.current-status-placeholder, .current-status-placeholder--bis').openingHours({
        show: 'current-status',
        hours: data
    });

    //newsletter checkbox
    $('#newsletter_check').change(function () {
        if ($('#newsletter_check').is(':checked')) {
            $('.cmApp_embedFormContainer input[type="submit"]').addClass('active');
        }
        else {
            $('.cmApp_embedFormContainer input[type="submit"]').removeClass('active');
        }
    });




});
