//map
function new_map( $el ) {

    // var
    var $markers = $el.find('.marker');


    // vars
    var args = {
        zoom		: 13,
        center		: new google.maps.LatLng($markers.attr('data-lat'), $markers.attr('data-lng')),
        styles      : [
            {
                "featureType": "water",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#e9e9e9"
                    },
                    {
                        "lightness": 17
                    }
                ]
            },
            {
                "featureType": "landscape",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#f5f5f5"
                    },
                    {
                        "lightness": 20
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#ffffff"
                    },
                    {
                        "lightness": 17
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "geometry.stroke",
                "stylers": [
                    {
                        "color": "#ffffff"
                    },
                    {
                        "lightness": 29
                    },
                    {
                        "weight": 0.2
                    }
                ]
            },
            {
                "featureType": "road.arterial",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#ffffff"
                    },
                    {
                        "lightness": 18
                    }
                ]
            },
            {
                "featureType": "road.local",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#ffffff"
                    },
                    {
                        "lightness": 16
                    }
                ]
            },
            {
                "featureType": "poi",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#f5f5f5"
                    },
                    {
                        "lightness": 21
                    }
                ]
            },
            {
                "featureType": "poi.park",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#dedede"
                    },
                    {
                        "lightness": 21
                    }
                ]
            },
            {
                "elementType": "labels.text.stroke",
                "stylers": [
                    {
                        "visibility": "on"
                    },
                    {
                        "color": "#ffffff"
                    },
                    {
                        "lightness": 16
                    }
                ]
            },
            {
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "saturation": 36
                    },
                    {
                        "color": "#333333"
                    },
                    {
                        "lightness": 40
                    }
                ]
            },
            {
                "elementType": "labels.icon",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "transit",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#f2f2f2"
                    },
                    {
                        "lightness": 19
                    }
                ]
            },
            {
                "featureType": "administrative",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#fefefe"
                    },
                    {
                        "lightness": 20
                    }
                ]
            },
            {
                "featureType": "administrative",
                "elementType": "geometry.stroke",
                "stylers": [
                    {
                        "color": "#fefefe"
                    },
                    {
                        "lightness": 17
                    },
                    {
                        "weight": 1.2
                    }
                ]
            }
        ],
        mapTypeId	: google.maps.MapTypeId.ROADMAP
    };

    // create map
    var map = new google.maps.Map( $el[0], args);

    // add a markers reference
    map.markers = [];

    // add markers
    $markers.each(function(){

        add_marker( $(this), map );

    });

    // center map
    //center_map( map );

    // return
    return map;
}

//var locations = [
//    ['Bondi Beach', -33.890542, 151.274856, 4],
//    ['Coogee Beach', -33.923036, 151.259052, 5],
//    ['Cronulla Beach', -34.028249, 151.157507, 3],
//    ['Manly Beach', -33.80010128657071, 151.28747820854187, 2],
//    ['Maroubra Beach', -33.950198, 151.259302, 1]
//];
//
//var marker, i;
//
//for (i = 0; i < locations.length; i++) {
//    marker = new google.maps.Marker({
//        position: new google.maps.LatLng(locations[i][1], locations[i][2]),
//        map: map
//    });
//
//    google.maps.event.addListener(marker, 'click', (function(marker, i) {
//        return function() {
//            infowindow.setContent(locations[i][0]);
//            infowindow.open(map, marker);
//        }
//    })(marker, i));
//}

function add_marker( $marker, map ) {

    //MAIN MARKER

    // var
    var latlng = new google.maps.LatLng( $marker.attr('data-lat'), $marker.attr('data-lng') );

    //icon
    var image = '/wp-content/themes/fermalux/img/_icons/marker.png';

    // create marker
    var marker = new google.maps.Marker({
        position	: latlng,
        map			: map,
        icon        : image
    });

    // add to array
    map.markers.push( marker );

    // if marker contains HTML, add it to an infoWindow
    //if( $marker.html() )
    //{
    //    // create info window
    //    var infowindow = new google.maps.InfoWindow({
    //        content		: $marker.html()
    //    });
//
    //    // show info window when marker is clicked
    //    google.maps.event.addListener(marker, 'click', function() {
//
    //        infowindow.open( map, marker );
//
    //    });
    //}


    //MORE MARKERS
    //var locations = [
    //    ['Réalisation 1', 50.440650, 4.889405, 1],
    //    ['Réalisation 2', 50.451503, 4.904929, 2],
    //    ['Réalisation 3', 50.434700, 4.924264, 3],
    //    ['Réalisation 4', 50.453588, 4.871122, 4],
    //    ['Réalisation 5', 50.469169, 4.909064, 5],
    //    ['Réalisation 6', 50.419025, 4.938100, 6],
    //    ['Réalisation 7', 50.410454, 4.935192, 7]
    //];
    var locations = [];
    var currentProject = 1;
    $( ".map__projects li" ).each(function( index ) {

        locations.push([$(this).find('h2').html(), $(this).data('lat'), $(this).data('lng'), currentProject]);
        currentProject++;

    });

    for (i = 0; i < locations.length; i++) {

        latlng = new google.maps.LatLng(locations[i][1], locations[i][2]);
        image = '/wp-content/themes/fermalux/img/_icons/marker_small.png';
        //itemnbr = locations[i][3];
        marker = new google.maps.Marker({
            position	: latlng,
            map			: map,
            //label       : itemnbr,
            icon        : image
        });
        map.markers.push( marker );

        //var itemnbr = locations[i][3];
        //google.maps.event.addListener(marker, 'click', function(i) {
        //    return function() {
        //        console.log(locations[i][0]);
        //    }
        //});
        google.maps.event.addListener(marker, 'click', (function(marker, i) {
            return function() {
                //console.log(locations[i][3]);
                $('.map__projects').removeClass('disabled');
                $('.map__porojects__item[data-project="'+locations[i][3]+'"]').removeClass("disabled")

                $('.map__porojects__item[data-project="'+locations[i][3]+'"] .owl-carousel').owlCarousel({
                    items:1,
                    loop:true,
                    autoplay:true,
                    autoplayTimeout:5000,
                    autoplayHoverPause:true,
                    animateIn: 'fadeIn',
                    animateOut: 'fadeOut'
                });

            }
        })(marker, i));

    }

}

function center_map( map ) {

    // vars
    var bounds = new google.maps.LatLngBounds();

    // loop through all markers and create bounds
    $.each( map.markers, function( i, marker ){

        var latlng = new google.maps.LatLng( marker.position.lat(), marker.position.lng() );

        bounds.extend( latlng );

    });

    // only 1 marker?
    if( map.markers.length == 1 )
    {
        // set center of map
        map.setCenter( bounds.getCenter() );
        map.setZoom( 14 );
    }
    else
    {
        // fit to bounds
        map.fitBounds( bounds );
    }

}

$(document).ready(function() {


    $('#map, #smallMap, #bigMap').each(function () {
        // create map
        map = new_map($(this));
    });

    //projects
    $('.map__project__back').on('click', function(){
        $(this).closest('.map__porojects__item').addClass('disabled');
        $('.map__projects').addClass('disabled');
    });
});